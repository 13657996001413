import { InfoCircleFilled } from "@ant-design/icons";
import { DataSource, DataSourceType } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import {
  COLORS,
  DATA_SOURCES_USE_SECONDARY_SQL_CONNECTION_FORM,
  SQL_DATASOURCES,
  SQL_FLOW_REUSE_DATASOURCES,
} from "../../constants";
import { getMaskedConnectionString } from "../../utils";
import { getUserEmail } from "../../utils/dataHelpers";
import { Tooltip } from "../Tooltip";

interface DataSourceInfoProps {
  dataSource?: DataSource;
  currentUserId?: string;
  hideImportDetails?: boolean;
  hideSharingDetails?: boolean;
}

const Wrap = styled.div`
  width: 220px;
  padding: 10px 5px;
  box-sizing: border-box;

  .row {
    margin-bottom: 16px;
    color: ${COLORS.black};

    .title {
      font-weight: 500;
      color: ${COLORS.black45};
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .box {
      background: ${COLORS.coeblue};
      color: ${COLORS.black45};
      border-radius: 5px;
      text-align: center;
      padding: 8px;
      line-height: 1.2;
      font-size: 12px;
    }
  }
`;

export const DataSourceInfo = ({
  dataSource,
  currentUserId,
  hideImportDetails,
  hideSharingDetails,
}: DataSourceInfoProps) => {
  return (
    <>
      {!dataSource && <></>}
      {dataSource && dataSource.data_source_type && (
        <Tooltip
          title={
            <DataSourceInfoWrap
              dataSource={dataSource}
              currentUserId={currentUserId}
              hideImportDetails={hideImportDetails}
              hideSharingDetails={hideSharingDetails}
            />
          }
          placement="bottomRight"
          arrowPointAtCenter={true}
          overlayStyle={{ pointerEvents: "none" }}
        >
          <InfoCircleFilled style={{ color: COLORS.black25 }} />
        </Tooltip>
      )}
    </>
  );
};

export const DataSourceInfoWrap = ({
  dataSource,
  currentUserId,
  hideImportDetails,
  hideSharingDetails,
}: DataSourceInfoProps) => {
  const selectedSchemas = dataSource?.credentials?.selected_schemas?.join(", ");
  const shouldShowHostForSqlLikeDatabases =
    dataSource?.data_source_type !== "databricks" &&
    (dataSource?.data_source_type !== "mongodb" ||
      !dataSource?.credentials?.host?.includes("mongodb+srv"));
  const shouldShowConnectionStringForSqlLikeDatabases =
    !shouldShowHostForSqlLikeDatabases &&
    dataSource &&
    DATA_SOURCES_USE_SECONDARY_SQL_CONNECTION_FORM.includes(dataSource.data_source_type);
  const connectionString = getMaskedConnectionString(dataSource?.credentials?.host);
  return (
    <Wrap>
      {dataSource?.data_source_type === "salesforce" && (
        <>
          <div className="row">
            <div className="title">Username</div>
            <div>{dataSource?.connection_info?.salesforce_preferred_username ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Organization ID</div>
            <div>{dataSource?.connection_info?.salesforce_organization_id ?? "-"}</div>
          </div>
          {dataSource?.ppress_metadata?.is_salesforce_sandbox && (
            <div className="row">
              <div className="title">Mode</div>
              <div>Sandbox</div>
            </div>
          )}
        </>
      )}

      {dataSource &&
        (SQL_DATASOURCES.includes(dataSource.data_source_type) ||
          SQL_FLOW_REUSE_DATASOURCES.includes(dataSource.data_source_type)) &&
        dataSource?.data_source_type !== "bigquery" && (
          <>
            {shouldShowHostForSqlLikeDatabases && (
              <div className="row">
                <div className="title">Host</div>
                <div>{dataSource?.credentials?.host}</div>
              </div>
            )}
            <div className="row">
              <div className="title">
                {dataSource?.data_source_type === "databricks" ? "Catalog name" : "Database name"}
              </div>
              <div>{dataSource?.credentials?.database_name}</div>
            </div>
            {shouldShowConnectionStringForSqlLikeDatabases && (
              <div className="row">
                <div className="title">Connection string</div>
                <div>{connectionString}</div>
              </div>
            )}
            {!shouldShowConnectionStringForSqlLikeDatabases && (
              <div className="row">
                <div className="title">Username</div>
                <div>{dataSource?.credentials?.username}</div>
              </div>
            )}
            {dataSource?.data_source_type === "snowflake" && (
              <div className="row">
                <div className="title">Warehouse</div>
                <div>{dataSource?.credentials?.snowflake_warehouse_name}</div>
              </div>
            )}
            {selectedSchemas && dataSource?.data_source_type !== "mongodb" && (
              <div className="row">
                <div className="title">Selected Schemas</div>
                <div>
                  {selectedSchemas.slice(0, 100) + (selectedSchemas.length > 100 ? "..." : "")}
                </div>
              </div>
            )}
          </>
        )}

      {dataSource?.data_source_type === "shopify" && (
        <>
          <div className="row">
            <div className="title">Username</div>
            <div>{dataSource?.connection_info?.shopify_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Shop Domain</div>
            <div>{dataSource?.ppress_metadata?.shop_url ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "quickbooks" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.quickbooks_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Company name</div>
            <div>{dataSource?.connection_info?.quickbooks_company_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "jira" && (
        <>
          <div className="row">
            <div className="title">Username</div>
            <div>
              {dataSource?.connection_info?.jira_email
                ? dataSource.connection_info.jira_email
                : "-"}
            </div>
          </div>
          <div className="row">
            <div className="title">Account ID</div>
            <div>{dataSource?.connection_info?.jira_account_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "hubspot" && (
        <>
          <div className="row">
            <div className="title">Username</div>
            <div>{dataSource?.connection_info?.hubspot_user_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Hub ID</div>
            <div>{dataSource?.connection_info?.hubspot_hub_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "zendesk" && (
        <>
          <div className="row">
            <div className="title">Username</div>
            <div>{dataSource?.connection_info?.zendesk_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Account URL</div>
            <div>{dataSource?.ppress_metadata?.subdomain ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "stripe" && (
        <>
          <div className="row">
            <div className="title">Business name</div>
            <div>{dataSource?.connection_info?.stripe_business_name ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Account owner</div>
            <div>{dataSource?.connection_info?.stripe_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Account ID</div>
            <div>{dataSource?.connection_info?.stripe_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "bigquery" && (
        <>
          <div className="row">
            <div className="title">GCP Project ID</div>
            <div>
              {dataSource.credentials.client_key_json
                ? dataSource.credentials.client_key_json["project_id"]
                : dataSource.credentials?.gcloud_project_id}
            </div>
          </div>
          {selectedSchemas && (
            <div className="row">
              <div className="title">Selected Datasets</div>
              <div>
                {selectedSchemas.slice(0, 100) + (selectedSchemas.length > 100 ? "..." : "")}
              </div>
            </div>
          )}
        </>
      )}

      {dataSource?.data_source_type === "looker" && (
        <div className="row">
          <div className="title">Base URL</div>
          <div>
            {dataSource?.ppress_metadata?.base_url.replace("https://", "") ??
              dataSource?.credentials?.url?.replace("https://", "")}
          </div>
        </div>
      )}

      {dataSource?.data_source_type === "tableau" && (
        <div className="row">
          <div className="title">Account URL</div>
          <div>{dataSource?.credentials?.url?.replace("https://", "")}</div>
        </div>
      )}

      {["google_analytics", "google_analytics_4"].includes(dataSource?.data_source_type ?? "") && (
        <div className="row">
          <div className="title">Username</div>
          <div>{dataSource?.connection_info?.google_analytics_username ?? "-"}</div>
        </div>
      )}

      {dataSource?.data_source_type === "facebook_ads" && (
        <div className="row">
          <div className="title">Username</div>
          <div>{dataSource?.connection_info?.facebook_ads_user_email ?? "-"}</div>
        </div>
      )}

      {dataSource?.data_source_type === "facebook_insights" && (
        <div className="row">
          <div className="title">Username</div>
          <div>{dataSource?.connection_info?.facebook_insights_user_email ?? "-"}</div>
        </div>
      )}

      {dataSource?.data_source_type === DataSourceType.InstagramInsights && (
        <div className="row">
          <div className="title">Email</div>
          <div>{dataSource?.connection_info?.instagram_insights_user_email ?? "-"}</div>
        </div>
      )}

      {dataSource?.data_source_type === "pipedrive" && (
        <>
          <div className="row">
            <div className="title">Organization Name</div>
            <div>{dataSource?.connection_info?.pipedrive_org_name ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Organization ID</div>
            <div>{dataSource?.connection_info?.pipedrive_company_id ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">User</div>
            <div>
              {dataSource?.connection_info?.pipedrive_user_name ?? "-"} (
              {dataSource?.connection_info?.pipedrive_user_email ?? "-"})
            </div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "mailchimp" && (
        <>
          <div className="row">
            <div className="title">Username (Role)</div>
            <div>
              {/* TODO: create a common method to handle all default "-"s */}
              {dataSource?.connection_info?.mailchimp_user_name ?? "-"} (
              {dataSource?.connection_info?.mailchimp_user_role ?? "-"})
            </div>
          </div>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.mailchimp_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "outreach" && (
        <>
          <div className="row">
            <div className="title">Organization Name</div>
            <div>{dataSource?.connection_info?.outreach_organization_name ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.outreach_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "google_search_console" && (
        <div className="row">
          <div className="title">Email</div>
          <div>{dataSource?.connection_info?.google_search_console_user_email ?? "-"}</div>
        </div>
      )}

      {dataSource?.data_source_type === "google_ads" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.google_ads_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "chargebee" && (
        <>
          <div className="row">
            <div className="title">Site Name</div>
            <div>{dataSource?.ppress_metadata?.site_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "linkedin_ads" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.linkedin_ads_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "youtube_analytics" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.youtube_analytics_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "linkedin_pages" && (
        <>
          <div className="row">
            <div className="title">LinkedIn User Name</div>
            <div>{dataSource?.connection_info?.linkedin_pages_user_localized_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "notion" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.notion_email ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Workspace</div>
            <div>{dataSource?.connection_info?.notion_workspace ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "airtable" && (
        <>
          <div className="row">
            <div className="title">User Id</div>
            <div>{dataSource?.connection_info?.airtable_user_id ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">User Email</div>
            <div>{dataSource?.connection_info?.airtable_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "microsoft_ads" && (
        <div className="row">
          <div className="title">Email</div>
          <div>{dataSource?.connection_info?.microsoft_ads_user_email}</div>
        </div>
      )}

      {dataSource?.data_source_type === "klaviyo" && (
        <div className="row">
          <div className="title">Email</div>
          <div>{dataSource?.connection_info?.klaviyo_user_email}</div>
        </div>
      )}

      {dataSource?.data_source_type === "asana" && (
        <div className="row">
          <div className="title">Email</div>
          <div>{dataSource?.connection_info?.asana_user_email}</div>
        </div>
      )}

      {dataSource?.data_source_type === "xero" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.xero_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "clickup" && (
        <>
          <div className="row">
            <div className="title">User name</div>
            <div>{dataSource?.connection_info?.clickup_user_name ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Workspaces</div>
            <div>{dataSource?.connection_info?.clickup_workspaces ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "typeform" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.typeform_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "freshdesk" && (
        <>
          <div className="row">
            <div className="title">Account name</div>
            <div>{dataSource?.connection_info?.freshdesk_account_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "paymo" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.paymo_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "harvest" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.harvest_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "razorpay" && (
        <>
          <div className="row">
            <div className="title">Razorpay Key Id</div>
            <div>{dataSource?.connection_info?.razorpay_key_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "google_calendar" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.google_calendar_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "trello" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.trello_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "gong" && (
        <>
          <div className="row">
            <div className="title">Account</div>
            <div>{dataSource?.connection_info?.gong_account_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "calendly" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.calendly_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "smartsheet" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.smartsheet_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "reddit_ads" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.reddit_ads_account_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "surveymonkey" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.surveymonkey_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "quora_ads" && (
        <>
          <div className="row">
            <div className="title">Account Name</div>
            <div>{dataSource?.connection_info?.quora_ads_user_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "podio" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.podio_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "greenhouse" && (
        <>
          <div className="row">
            <div className="title">User Name</div>
            <div>{dataSource?.connection_info?.greenhouse_api_key ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "active_campaign" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.active_campaign_account_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "basecamp" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.basecamp_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "jotform" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.jotform_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "zohobooks" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.zohobooks_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "zoho_crm" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.zoho_crm_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "bigin" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.bigin_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "gusto" && (
        <>
          <div className="row">
            <div className="title">Company Id</div>
            <div>{dataSource?.connection_info?.gusto_company_id ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "intercom" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.intercom_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "etsy" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.etsy_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "pinterest_ads" && (
        <>
          <div className="row">
            <div className="title">User name</div>
            <div>{dataSource?.connection_info?.pinterest_ads_user_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "coda" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.coda_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "square" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.square_user_business_name ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "zoom" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.zoom_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "amplitude" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.amplitude_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "webflow" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.webflow_user_email ?? "-"}</div>
          </div>
          {dataSource?.connection_info?.webflow_user_workspaces_or_sites && (
            <div className="row">
              <div className="title">Workspaces or Sites</div>
              <div>{dataSource?.connection_info?.webflow_user_workspaces_or_sites ?? "-"}</div>
            </div>
          )}
        </>
      )}

      {dataSource?.data_source_type === "lever" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.lever_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "salesloft" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.salesloft_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "netsuite" && (
        <>
          <div className="row">
            <div className="title">Account ID</div>
            <div>{dataSource?.connection_info?.netsuite_account_id ?? "-"}</div>
          </div>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.netsuite_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "smartlead" && (
        <>
          <div className="row">
            <div className="title">API Key</div>
            <div>{dataSource?.connection_info?.smartlead_api_key ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "affinity" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.affinity_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "shiprocket" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.shiprocket_api_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "rippling" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.rippling_work_email ?? "-"}</div>
          </div>
        </>
      )}

      {dataSource?.data_source_type === "apollo" && (
        <>
          <div className="row">
            <div className="title">Email</div>
            <div>{dataSource?.connection_info?.apollo_user_email ?? "-"}</div>
          </div>
        </>
      )}

      {!hideImportDetails && (
        <div className="row">
          <div className="title">Imports using this connection</div>
          <div>
            {(dataSource?.data_source_stats?.num_data_imports ?? 0) +
              (dataSource?.data_source_stats?.num_shared_data_imports ?? 0)}
          </div>
        </div>
      )}

      {!hideSharingDetails &&
        dataSource?.is_shared_with_domain &&
        dataSource?.user &&
        currentUserId &&
        dataSource?.user?.user_id !== currentUserId && (
          <div className="row">
            <div className="box">
              This connection is shared by {getUserEmail(dataSource?.user)}. Please contact them
              directly for any changes.
            </div>
          </div>
        )}
    </Wrap>
  );
};
