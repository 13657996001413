import { DataSourceType } from "@coeff/api";

export const DataSourceTypeDisplayNames: { [dsType in DataSourceType]: string } = {
  csv: "CSV",
  google_sheets: "Google Sheets",
  salesforce: "Salesforce",
  mysql: "MySQL",
  redshift: "Redshift",
  postgres: "PostgreSQL",
  snowflake: "Snowflake",
  mssql: "MS SQL Server",
  bigquery: "BigQuery",
  looker: "Looker",
  chargebee: "Chargebee",
  chargify: "Chargify",
  google_analytics: "Google Analytics (UA)",
  google_analytics_4: "Google Analytics 4",
  facebook_ads: "Facebook Ads",
  hubspot: "HubSpot",
  marketo: "Marketo",
  netsuite: "NetSuite",
  quickbooks: "QuickBooks",
  shopify: "Shopify",
  stripe: "Stripe",
  tableau: "Tableau",
  jira: "Jira",
  zendesk: "Zendesk",
  google_drive: "Google Drive",
  amplitude: "Amplitude",
  airtable: "Airtable",
  gong: "Gong",
  klaviyo: "Klaviyo",
  mailchimp: "Mailchimp",
  mongodb: "Mongodb",
  outreach: "Outreach",
  pipedrive: "Pipedrive",
  sendgrid: "Sendgrid",
  excel: "Excel",
  one_drive: "One Drive",
  google_search_console: "Google Search Console",
  google_ads: "Google Ads",
  notion: "Notion",
  greenhouse: "Greenhouse",
  xero: "Xero",
  custom_api: "Connect Any API",
  web_scraper: "Web Scraper",
  gpt_copilot: "GPT Co-pilot",
  cloud_table: "Cloud Table",
  linkedin_ads: "LinkedIn Ads",
  facebook_insights: "Facebook Insights",
  instagram_insights: "Instagram Insights",
  linkedin_pages: "LinkedIn Pages",
  youtube_analytics: "YouTube Analytics",
  microsoft_ads: "Microsoft Ads",
  asana: "Asana",
  razorpay: "Razorpay",
  clickup: "ClickUp",
  typeform: "Typeform",
  harvest: "Harvest",
  freshdesk: "Freshdesk",
  paymo: "Paymo",
  google_calendar: "Google Calendar",
  trello: "Trello",
  calendly: "Calendly",
  smartsheet: "Smartsheet",
  quora_ads: "Quora Ads",
  reddit_ads: "Reddit Ads",
  surveymonkey: "SurveyMonkey",
  podio: "Podio",
  gusto: "Gusto",
  intercom: "Intercom",
  etsy: "Etsy",
  pinterest_ads: "Pinterest Ads",
  active_campaign: "ActiveCampaign",
  basecamp: "Basecamp",
  jotform: "Jotform",
  zohobooks: "Zoho Books",
  coda: "Coda",
  square: "Square",
  zoom: "Zoom",
  webflow: "Webflow",
  bigin: "Bigin",
  zoho_crm: "Zoho CRM",
  lever: "Lever",
  salesloft: "Salesloft",
  woo_commerce: "WooCommerce",
  wordpress: "WordPress",
  bigcommerce: "BigCommerce",
  smartlead: "Smartlead",
  affinity: "Affinity",
  shiprocket: "Shiprocket",
  databricks: "Databricks SQL",
  rippling: "Rippling",
  apollo: "Apollo",
  sage: "Sage Intacct",
};

export const getMaskedConnectionString = (connectionStr: string | undefined): string => {
  try {
    if (connectionStr === undefined) {
      return "*****";
    }
    if (String(connectionStr).includes("jdbc:databricks")) {
      const passwordToMask = connectionStr.substring(connectionStr.lastIndexOf("PWD=") + 4);
      // @ts-ignore
      return connectionStr.replaceAll(passwordToMask, "*****");
    }
    if (!String(connectionStr).includes("mongodb+srv")) {
      return String(connectionStr);
    }
    // @ts-ignore
    const usernamePass = connectionStr.match(/mongodb\+srv:\/\/([^@]+)/)[1];
    const passwordToMask = usernamePass.substring(usernamePass.lastIndexOf(":") + 1);
    // @ts-ignore
    return connectionStr.replaceAll(passwordToMask, "*****");
  } catch (error) {
    return "*****";
  }
};
